<template>
  <div class="setting-bank-th style-box-setting">
    <div class="style-box-setting-header">
      <v-row>
        <v-col cols="4">
          <div class="title-header">
            Gestion des banques
          </div>
        </v-col>
        <v-col cols="2">
          <v-progress-circular
            class="ml-2"
            v-if="getBankLoading"
            indeterminate
            color="#704ad1"
          ></v-progress-circular>
        </v-col>
        <v-col cols="3">
          <div class="error-message ml-2 mr-2 mt-3">
            <div v-if="getBankError">
              <ul v-if="Array.isArray(getBankError)" class="mb-0">
                <li v-for="(e, index) in getBankError" :key="index">
                  {{ e }}
                </li>
              </ul>
              <div v-else>{{ getBankError }}</div>
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
    <v-divider></v-divider>
    <div class="box-content">
      <div class="card-block-filter-setting">
        <div class="right-block-filter"></div>
        <div class="left-block-filter">
          <div>
            <search
              :value="search"
              @changeSearchValue="changeSearchValue"
              classToUse="search-style"
            />
          </div>
          <v-tooltip bottom color="#311B92">
            <template v-slot:activator="{ on, attrs }">
              <div
                class="block-icon-setting text-center mr-1"
                v-on="on"
                v-bind="attrs"
                @click="handelClickExport()"
              >
                <font-awesome-icon icon="cloud-download-alt" />
              </div>
            </template>
            <span>Télecharger xls</span>
          </v-tooltip>
          <v-tooltip bottom color="#311B92">
            <template v-slot:activator="{ on, attrs }">
              <div
                class="block-icon-setting text-center mr-1"
                v-on="on"
                v-bind="attrs"
                @click="openModalAjouterBank"
              >
                <font-awesome-icon icon="plus-circle" />
              </div>
            </template>
            <span>Ajouter une banque</span>
          </v-tooltip>
        </div>
      </div>
    </div>
    <div class="table-gestion-content-bank pt-2">
      <v-data-table
        v-model="selectedTable"
        :headers="fields"
        :items="getBankRows"
        :items-per-page="getBankRows.length"
        class="table-gestion-bank"
        hide-default-footer
        disable-sort
        :calculate-widths="false"
        :fixed-header="true"
        hide-action
        :no-data-text="
          getBankLoading
            ? 'Chargement... Veuillez patienter'
            : `Il n'y a aucune donnée à afficher dans ce tableau.`
        "
      >
        <template v-slot:[`item.name`]="{ item }">
          {{ item.name }}
        </template>
        <template v-slot:[`item.Actions`]="{ item }">
          <td class="custom-cell">
            <v-btn
              small
              class="block-icon-reglement-fac mr-2"
              title="Modifier"
              @click.prevent.stop="handleClickUpdateBank(item)"
            >
              <font-awesome-icon icon="pencil-alt" />
            </v-btn>
          </td>
        </template>
      </v-data-table>
    </div>
    <v-dialog
      v-model="addBank"
      max-width="820px"
      scrollable
      hide-overlay
      persistent
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">Ajouter une banque</v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click.prevent="hideModal('addBank')"
            title="Fermer"
            color="#704ad1"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text
          class="body-card center-text  mt-2"
          v-if="bankToAdd != null"
        >
          <v-form ref="formAdd" lazy-validation>
            <v-text-field
              label="Nom"
              v-model="bankToAdd.name"
              outlined
              required
              dense
              :persistent-placeholder="true"
              :rules="[v => !!v || 'Nom est obligatoire']"
              class="msg-error mt-2"
              validate-on-blur
              color="#704ad1"
            ></v-text-field>
          </v-form>
        </v-card-text>
          <div v-if="getBankError" class="message-error-modal">
            <ul
              v-if="Array.isArray(getBankError)"
              class="mb-0"
              style="list-style-type: none"
            >
              <li v-for="(e, index) in getBankError" :key="index">
                {{ e }}
              </li>
            </ul>
            <div v-else>{{ getBankError }}</div>
          </div>
        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <v-spacer></v-spacer>
          <v-btn
            color="#704ad1"
            outlined
            @click="submitAddBank"
            :loading="loandingAddBank"
            :disabled="loandingAddBank"
            :class="{ loader: loandingAddBank }"
          >
            Valider
          </v-btn>
          <v-btn outlined @click.prevent="hideModal('addBank')">
            Annuler
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="updateBankModal"
      max-width="820px"
      scrollable
      hide-overlay
      persistent
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">Modifier une banque</v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click.prevent="hideModal('updateBankModal')"
            title="Fermer"
            color="#704ad1"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text
          class="body-card center-text  mt-2"
          v-if="bankToUpdate != null"
        >
          <v-form ref="formUpdate" lazy-validation>
            <v-text-field
              label="Nom"
              v-model="bankToUpdate.name"
              outlined
              required
              dense
              :persistent-placeholder="true"
              :rules="[v => !!v || 'Nom est obligatoire']"
              class="msg-error mt-2"
              validate-on-blur
              color="#704ad1"
            ></v-text-field>
          </v-form>
        </v-card-text>
          <div v-if="getBankError" class="message-error-modal">
            <ul
              v-if="Array.isArray(getBankError)"
              class="mb-0"
              style="list-style-type: none"
            >
              <li v-for="(e, index) in getBankError" :key="index">
                {{ e }}
              </li>
            </ul>
            <div v-else>{{ getBankError }}</div>
          </div>
        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <v-spacer></v-spacer>
          <v-btn
            color="#704ad1"
            outlined
            @click="submitUpdateBank"
            :loading="loandingUpdateBank"
            :disabled="loandingUpdateBank"
            :class="{ loader: loandingUpdateBank }"
          >
            Valider
          </v-btn>
          <v-btn outlined @click.prevent="hideModal('updateBankModal')">
            Annuler
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  data() {
    return {
      page: 1,
      perPage: 10,
      search: null,
      perPageList: [
        { value: 10, text: 10 },
        { value: 20, text: 20 },
        { value: 50, text: 50 }
      ],
      selectedTable: [],
      fields: [
        {
          value: 'name',
          text: 'Nom',
          width: '100% !important'
        },
        {
          value: 'Actions',
          text: 'Actions',
          thClass: 'thvalidation',
          tdClass: 'tdvalidation'
        }
      ],
      bankToAdd: {
        name: null
      },
      bankToUpdate: null,
      searchValue: null,
      loandingAddBank: false,
      loandingUpdateBank: false,
      bankToUpdateOrigin: null,
      addBank: false,
      updateBankModal: false
    };
  },
  computed: {
    ...mapGetters([
      'getBankTotalRows',
      'getBankError',
      'getBankLoading',
      'getBankRows'
    ]),
    rowDetailsClass(index) {
      if (index % 2 == 0) {
        return 'tdvalidation';
      } else {
        return 'ligneEnAttend';
      }
    }
  },
  methods: {
    ...mapActions([
      'getAllBanks',
      'createBnak',
      'updateBank',
      'resetVarBank',
      'exporXlsBanque'
    ]),
    openModalAjouterBank() {
      this.addBank = true;
    },
    resetModal() {
      this.resetVarBank();
      this.bankToAdd = {
        name: null
      };
      this.bankToUpdate = null;
      this.bankToUpdateOrigin = null;
    },
    changeSearchValue(event) {
      this.search = event;
      this.getAllBanks({
        search: this.search
      });
    },
    hideModal(ref) {
      this[ref] = false;
      if (ref == 'addBank') {
        this.$refs.formAdd.reset();
      }
      if (ref == 'updateBankModal') {
        this.$refs.formUpdate.reset();
      }
      this.resetModal();
    },
    async submitAddBank() {
      if (this.$refs.formAdd.validate()) {
        this.loandingAddBank = true;
        const response = await this.createBnak(this.bankToAdd);
        if (response) {
          this.hideModal('addBank');
        }
        this.loandingAddBank = false;
      }
    },
    handleClickUpdateBank(bank) {
      this.bankToUpdate = { ...bank };
      this.bankToUpdateOrigin = bank;
      this.updateBankModal = true;
    },
    async submitUpdateBank() {
      if (this.$refs.formUpdate.validate()) {
        this.loandingUpdateBank = true;
        const response = await this.updateBank({
          bank: this.bankToUpdate,
          bankOrigin: this.bankToUpdateOrigin
        });
        if (response) {
          this.hideModal('updateBankModal');
        }
        this.loandingUpdateBank = false;
      }
    },
    handelClickExport() {
      this.exporXlsBanque();
    }
  },
  components: {
    search: () => import('@/views/component/SearchInputComponent.vue')
  },
  mounted() {
    this.getAllBanks();
  }
};
</script>

<style scoped lang="scss">
.table-gestion-content-bank {
  padding: 0px 5px 0px 5px;
  max-height: calc(100vh - 280px) !important;
  height: calc(100vh - 280px) !important;
  margin-bottom: 0;
}

.loadingFetch {
  width: 16px;
  height: 16px;
  font-size: 10px;
}
.setting-bank-th {
  .table-bank-params {
    max-height: calc(100vh - 179px) !important;
    height: calc(100vh - 179px) !important;
    margin-bottom: 0px;
    .button-danger-style {
      background-color: #dc3c3c;
      font-family: 'Montserrat', sans-serif;
      font-size: 11px;
      font-weight: 600;
      color: #ffffff;
      border-radius: 25px;
      padding: 6px 15px;
      border: none;
    }
    .button-warning-style {
      background-color: #ffc107;
      font-family: 'Montserrat', sans-serif;
      font-size: 15px;
      color: #ffffff;
      border: none;
    }
    .button-duplicate-style {
      background-color: #17a2b8;
      font-family: 'Montserrat', sans-serif;
      font-size: 15px;
      color: #ffffff;
      border: none;
    }
    .button-modifier-style {
      background-color: #699d86;
      font-family: 'Montserrat', sans-serif;
      font-size: 15px;
      color: #ffffff;
      border: none;
    }
  }

  .body-box-setting {
    display: flex;
    background-color: #f5f4fa;
    height: auto;
    margin-top: 3px;
    border-radius: 28px;
    position: relative;
    padding: 11px;
  }
  .table-biens-params {
    .thvalidation,
    .tdvalidation {
      min-width: 107px !important;
    }
  }
  .bien-block {
    color: #000;
    position: relative;
    overflow: visible;
    font-size: 12px;
    margin-top: 5px;
    border: 1px solid rgba(108, 117, 125, 0.3215686275);
    padding: 4px;
    background-color: #f5f4fa;
    width: 90%;
    border-radius: 10px;
    margin: 0px auto;
  }
  .table-biens-params {
    .color-row-bien {
      min-width: 42px;
    }
    .row-action-bien {
      min-width: 107px !important;
    }
    .item-details-style {
      border: none;
    }
  }
}
</style>
<style lang="scss">
.table-gestion-bank {
  table {
    border-collapse: collapse;
  }
  height: 100% !important;
  max-height: 100% !important;
  .v-data-table__wrapper {
    max-height: calc(100vh - 204px) !important;
    height: calc(100vh - 204px) !important;
    margin-bottom: 0;
    overflow-y: auto !important;
    overflow-x: hidden !important;
  }
  .table {
    table-layout: fixed !important;
    border-collapse: collapse !important;
  }
  th.text-start {
    min-width: 150px !important;
    width: 150px !important;
  }
  .v-data-table-header {
    text-align: center;
    .text-start {
      border: 1px solid #e5e5e5 !important;
      vertical-align: inherit;
      // white-space: nowrap;
      font-size: 11px;
      padding: 0px !important;
      text-align: center !important;
    }
  }
  tbody {
    text-align: center;
    color: #656565;

    td {
      border: 1px solid #e5e5e5 !important;
      vertical-align: inherit;
      font-size: 12px !important;
      font-family: 'Montserrat', sans-serif;
      text-align: center !important;
      padding: 0px !important;
      height: 39px !important;
      .checked-groupe {
        margin-top: 0px !important;
        padding-top: 0px !important;
        float: right !important;
      }
    }
    tr {
      .text-start,
      .custom-cell {
        border: 1px solid #e5e5e5 !important;
        vertical-align: inherit;
        // white-space: nowrap;
        font-size: 11px;
        // padding: 7px 2px !important;
        text-align: center !important;
      }
    }
  }
  thead {
    tr {
      th {
        .checked-global {
          padding-left: 8px;
        }
        span {
          font-weight: 600;
          color: black;
        }
        td {
          .v-input--selection-controls {
            margin-top: 0px !important;
            padding-top: 0px !important;
            float: right !important;
          }
        }
      }
    }
  }
}
</style>
